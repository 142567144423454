import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import managerTestApi from "api/managerTest";
import {useAppSelector} from "app/hooks";
import clsx from "clsx";
import {selectInfoLogin} from "features/Auth/authSlice";
import {
  AnswerContent,
  AnswerRight,
  DetailTestByUser,
  ExamQuestions,
  ListExam,
  StudentAnswers,
} from "models/managerTest";
import React from "react";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {scroller} from "react-scroll";
import {toast} from "react-toastify";
import {secondsToHms} from "utils/timeConvert";

export default function DetailTest() {
  const useStyles = makeStyles((theme: Theme) => ({
    title: {
      color: "#004673",
      fontWeight: 700,
      fontSize: 20,
      padding: "10px 0",
    },
    header: {
      backgroundColor: "#137BBD",
      width: "100%",
      marginTop: 20,
    },

    header__title: {
      padding: "10px 40px",
      borderBottom: "1px solid #FFFFFF",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > h6": {
        fontSize: 20,
        fontWeight: 600,
        color: "#FFFFFF",
      },
    },
    header__bg: {
      color: "#DB2139",
      backgroundColor: "#FFFFFF",
      padding: "2px 30px",
      fontSize: 25,
      fontWeight: 700,
      borderRadius: 8,
    },
    header__ranking: {
      marginLeft: 40,
      "& > span": {
        color: "#004673",
        fontSize: 20,
      },
    },
    exam: {
      backgroundColor: "#FFFFFF",
      boxShadow: " 0px 1px 4px rgba(168, 168, 168, 0.4)",
      borderRadius: 6,
      padding: "10px 15px",
      marginBottom: 15,
    },
    question__content: {
      color: "#0065A6",
      fontSize: 18,
      "&> p": {
        fontSize: 15,
        marginLeft: 10,
        color: "#000000",
      },
    },
    btn_submit: {
      background: "linear-gradient(0deg, #137BBD 0%, rgba(105, 197, 255, 0.8) 145.33%)",
      borderRadius: 10,
      padding: "10px 20px",
      color: "#FFFFFF",
    },
    icon_scroll: {
      position: "fixed",
      right: 30,
      bottom: 25,
      display: "flex",
      flexDirection: "column",
      "& > svg": {
        fontSize: 30,
        color: "#0065A6",
        cursor: "pointer",
        marginTop: 10,
      },
    },
    btn_goBack: {
      position: "absolute",
      top: -15,
      left: -25,
    },
    userChecked: {
      position: "absolute",
      width: 11,
      height: 11,
      borderRadius: "50%",
      backgroundColor: "#565656",
      top: 15.5,
      left: 4,
    },
    checkIconRight: {
      color: "#1FDA53",
      marginRight: 40,
      fontSize: 25,
    },
    question_number: {
      color: "#0065A6",
      fontSize: 16,
    },
    question_content: {
      fontSize: 15,
    },
    answer_right_typing: {
      color: "#137bbd",
      marginTop: 5,
    },
  }));
  const {t} = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const location: any = useLocation();
  const navigate = useNavigate();
  const infoUser = useAppSelector(selectInfoLogin);
  const [detailTest, setDetailTest] = useState<ListExam>();
  const [detailTestByUser, setDetailTestByUser] = useState<DetailTestByUser>();
  const [detailExamHasAnswer, setDetailExamHasAnswer] = useState<ListExam>();
  const [scoreList, setScoreList] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExam(Number(location.state.examId));
      setDetailTest(res);
    })();
  }, []);

  const handleFetchDetailTestByUser = useCallback(async () => {
    const res: DetailTestByUser = await managerTestApi.getDetailTestByUser(params.id);
    setDetailTestByUser(res);
    const scoreList = res?.submissionDetails.studentAnswers.map((x) => ({
      question_id: x.questionId,
      score: x.typingPoint,
    }));
    setScoreList(scoreList);
  }, []);
  useEffect(() => {
    handleFetchDetailTestByUser();
  }, [params]);
  useEffect(() => {
    (async () => {
      const res: ListExam = await managerTestApi.getDetailExamHasAnswers(
        Number(location.state.examId)
      );
      setDetailExamHasAnswer(res);
    })();
  }, []);

  const handleScrollToTop = () => {
    scroller.scrollTo("header", {
      duration: 800,
      smooth: "easeInOutQuart",
      offset: -80,
    });
  };
  const handleScrollToBottom = () => {
    scroller.scrollTo("footer", {
      duration: 800,
      smooth: "easeInOutQuart",
      offset: -80,
    });
  };
  const handleChangeScore = (event: ChangeEvent<HTMLInputElement>, question: ExamQuestions) => {
    const foundItem = scoreList.findIndex((n) => n.question_id === question.questionId);
    const newItem = [...scoreList];
    newItem[foundItem].score = event.target.value;
    setScoreList(newItem);
  };
  const handleEditTest = async () => {
    const maxPoint = detailTestByUser?.submissionDetails.maxPoint;
    const totalPointChange = scoreList.reduce((x, y) => x + Number(y.score), 0);
    if (maxPoint && totalPointChange > maxPoint) {
      toast.error(`${t("managerTest.vuilongnhaptongsodiemnhohonhoacbangtongdiembaithi")}`);
      return;
    }
    try {
      const res = await managerTestApi.updateExamScore({
        submission_details_id: params.id,
        score_list: scoreList,
      });
      if (res.responseCode === "00") {
        toast.success(`${t("managerTest.capnhatdiemthanhcong")}`);
        handleFetchDetailTestByUser();
      }
      if (res.responseDescription === "MISSING_MANDATORY_VALUE") {
        toast.error(`${t("managerTest.vuilongnhapduthongtin")}`);
      }
    } catch (error) {}
  };
  return (
    <Box style={{position: "relative"}} pt={3}>
      <Box className={classes.btn_goBack}>
        <Tooltip title="Quay lại">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon style={{marginLeft: 7}} />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography variant="h4" className={classes.title}>
        {t("managerTest.thongtinchung")}
      </Typography>
      <Box className={classes.header} id="header">
        <Box className={classes.header__title}>
          <Typography variant="subtitle2">{infoUser.name}</Typography>
          <Box display="flex" justifyContent="space-between">
            <Box className={clsx(classes.header__ranking, classes.header__bg)}>
              {detailTestByUser?.submissionDetails?.point || 0}
              <Typography variant="subtitle2" component="span">
                {t("managerTest.diem").toLowerCase()}
              </Typography>
            </Box>

            <Box
              className={clsx(classes.header__ranking, classes.header__bg)}
              display="flex"
              alignItems="center"
            >
              <Typography variant="subtitle2" component="span">
                {t("managerTest.tongdiembaithi")}
                {" : "}
              </Typography>
              <Typography variant="body2" style={{color: "#DB2139", fontSize: 25}}>
                {detailTestByUser?.submissionDetails?.maxPoint || 0}
              </Typography>
            </Box>
            {/* <Box className={clsx(classes.header__ranking, classes.header__bg)}>
              1
              <Typography variant="subtitle2" component="span">
                /1800
              </Typography>
            </Box> */}
          </Box>
        </Box>
        <Box className={classes.header__title}>
          <Typography variant="subtitle2">{detailTest?.exam?.examTitle}</Typography>
          <Box className={classes.header__bg}>
            {secondsToHms(detailTestByUser?.submissionDetails?.duration)}
          </Box>
        </Box>
      </Box>

      {detailTestByUser &&
        detailTest &&
        detailTestByUser.submissionDetails.studentAnswers.length > 0 &&
        scoreList.length > 0 &&
        detailTest.exam.examQuestions.length > 0 &&
        detailTest.exam.examQuestions.map((question: ExamQuestions, index: number) => (
          <Box className={classes.exam} key={question.id}>
            <Box key={question.id}>
              {location?.state?.editPoint ? (
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                  <Box display="flex">
                    <Typography variant="subtitle2" className={classes.question_number}>
                      {t("managerTest.cau")} {index + 1} :{" "}
                      <Typography
                        variant="h6"
                        component="span"
                        className={classes.question_content}
                      >
                        {question.question.content}
                      </Typography>
                    </Typography>
                  </Box>
                  {question.question.imageFileList.length > 0 && (
                    <Box
                      display="grid"
                      gridTemplateColumns={
                        question?.question?.imageFileList?.length === 1 ? "1fr" : "1fr 1fr"
                      }
                      gridGap={10}
                      my="10px"
                    >
                      {question.question.imageFileList.map((x: any) => {
                        return (
                          <img
                            key={x.id}
                            style={{
                              width: "100%",
                              height: "fit-content",

                              objectFit: "contain",
                              maxHeight: "450px",
                            }}
                            src={x.imageFile.url}
                            alt={x}
                          />
                        );
                      })}
                    </Box>
                  )}
                  <Box width="100px">
                    <TextField
                      label={t("managerTest.diem")}
                      name="score"
                      variant="outlined"
                      autoComplete="off"
                      defaultValue={
                        scoreList.filter((x) => x.question_id === question.questionId)[0]?.score
                      }
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleChangeScore(event, question)
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle2" className={classes.question_number}>
                      {t("managerTest.cau")} {index + 1} :
                    </Typography>
                    <Typography variant="h6" className={classes.question_content}>
                      {question.question.content}
                    </Typography>
                  </Box>
                  {question.question.imageFileList.length > 0 && (
                    <Box
                      display="grid"
                      gridTemplateColumns={
                        question?.question?.imageFileList?.length === 1 ? "1fr" : "1fr 1fr"
                      }
                      gridGap={10}
                      my="10px"
                    >
                      {question.question.imageFileList.map((x: any) => {
                        return (
                          <img
                            key={x.id}
                            style={{
                              width: "100%",
                              height: "fit-content",

                              objectFit: "contain",
                              maxHeight: "450px",
                            }}
                            src={x.imageFile.url}
                            alt={x}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Box>
              )}

              {detailTest?.exam?.type === "TYPING" ? (
                <>
                  {detailExamHasAnswer?.questions?.map(
                    (answerRight: AnswerRight) =>
                      question.questionId === answerRight.questionId &&
                      answerRight?.answers?.map((answer: AnswerContent) => (
                        <Box key={answerRight.questionId}>
                          {detailTestByUser?.submissionDetails?.studentAnswers?.map(
                            (answerByStudent: StudentAnswers) =>
                              answerByStudent.questionId === answerRight.questionId && (
                                <Box key={answerByStudent.id}>
                                  <Typography variant="subtitle2">
                                    {answerByStudent.typing}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.answer_right_typing}
                                  >
                                    {t("managerTest.dapandung")} : {answer.content}
                                  </Typography>
                                </Box>
                              )
                          )}
                        </Box>
                      ))
                  )}
                </>
              ) : (
                <RadioGroup name="choice">
                  {detailExamHasAnswer?.questions?.map(
                    (answerRight: AnswerRight) =>
                      answerRight.questionId === question.questionId &&
                      answerRight?.answers?.map((answer: AnswerContent) => (
                        <Box
                          key={answer.id}
                          position="relative"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {detailTestByUser?.submissionDetails?.studentAnswers?.map(
                            (answerByStudent: StudentAnswers) => (
                              <Box
                                key={answerByStudent.id}
                                className={classes.userChecked}
                                style={{
                                  display:
                                    answerByStudent.choice === answer.id &&
                                    answerByStudent.questionId === question.questionId
                                      ? "block"
                                      : "none",
                                }}
                              ></Box>
                            )
                          )}

                          <FormControlLabel
                            control={
                              <Radio
                                checked={answer.isRightAnswer}
                                style={{
                                  color: answer.isRightAnswer ? "#1FDA53" : "rgba(0, 0, 0, 0.24)",
                                }}
                                disabled={!answer.isRightAnswer}
                              />
                            }
                            label={
                              <Typography
                                variant="subtitle2"
                                style={{
                                  fontWeight: answer.isRightAnswer ? 700 : 500,
                                }}
                              >
                                {answer?.content}
                              </Typography>
                            }
                          />
                          {answer.isRightAnswer && (
                            <Box className={classes.checkIconRight}>
                              <CheckIcon />
                            </Box>
                          )}
                          {detailTestByUser?.submissionDetails?.studentAnswers?.map(
                            (answerByStudent: StudentAnswers) =>
                              answerByStudent.isCorrect === false &&
                              answerByStudent.questionId === question.questionId &&
                              answerByStudent.choice === answer.id && (
                                <Box
                                  className={classes.checkIconRight}
                                  style={{color: "red"}}
                                  key={answerByStudent.id}
                                >
                                  <CloseIcon />
                                </Box>
                              )
                          )}
                        </Box>
                      ))
                  )}
                </RadioGroup>
              )}
            </Box>
          </Box>
        ))}
      {location?.state?.editPoint && (
        <Box textAlign="center">
          <Button className={classes.btn_submit} onClick={handleEditTest}>
            {t("admin.chinhsua")}
          </Button>
        </Box>
      )}
      <Box className={classes.icon_scroll}>
        <Tooltip title={t("test.arrow.dichuyenlendautrang") || ""}>
          <ExpandLessIcon onClick={handleScrollToTop} />
        </Tooltip>
        <Tooltip title={t("test.arrow.dichuyenxuongcuoitrang") || ""}>
          <ExpandMoreIcon onClick={handleScrollToBottom} />
        </Tooltip>
      </Box>
    </Box>
  );
}
