import {ExamQuestions, ListExam} from "models/managerTest";
import React, {useState} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Theme, createStyles, makeStyles} from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slide,
} from "@material-ui/core";
import {dateTimeToFormatYYYYHHMM} from "utils/dateTimeFormat";
import {useTranslation} from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import {toast} from "react-toastify";

export interface PickOldExamProps {
  data: ListExam[];
  setCreateQuestion: (e: any) => void;
  listQuestionsAdded: any;
  setListQuestionsAdded: (e: any) => void;
  setIsPickOldExam: (e: any) => void;
  setIsShowMainExam: (e: any) => void;
}

export default function PickOldExamComponent({
  data,
  setCreateQuestion,
  setListQuestionsAdded,
  listQuestionsAdded,
  setIsPickOldExam,
  setIsShowMainExam,
}: PickOldExamProps) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
      },
      title: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 10,
      },
      question: {
        backgroundColor: "#fff",
        boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.25), 0px 0px 4px rgba(168, 168, 168, 0.25)",
        borderRadius: 6,
        padding: "20px 40px",
        position: "relative",
      },
      question__content: {
        color: "#0065A6",
        fontSize: 15,
        marginLeft: 5,
      },
      checkbox: {
        color: "#DADADA",
        borderRadius: 5,
        position: "absolute",
        right: -1,
        top: 10,
      },
      checkAll: {
        position: "absolute",
        top: -13,
        right: 82,
        zIndex: 9999,
      },
      checkbox_all: {
        color: "#DADADA",
        borderRadius: 5,
      },
      btn_create: {
        background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
        color: "#fff",
        borderRadius: 20,
        padding: "10px 15px",
      },
      btn_goBack: {
        borderRadius: 20,
        color: "#fff",
        marginRight: 15,
        background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
        padding: "10px 15px",
      },
      root_modal: {
        width: "100%",
        height: "60%",
        display: "flex",
        justifyContent: "center",
      },
      modal__container: {
        width: "100%",
        maxWidth: 450,
        borderRadius: 12,
        backgroundColor: "#fff",
        zIndex: 9,
        display: "flex",
        flexDirection: "column",
        margin: "30px 0",
        height: 230,
      },
      modal__header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 24px",
        minHeight: 65,
        borderBottom: "1px solid rgb(235, 235, 235)",
        "& >h6": {
          fontWeight: 700,
          fontSize: 18,
          marginLeft: 20,
        },
      },
      modal_content: {
        padding: "15px 30px",
      },
      input_confirm_question: {
        border: "none",
        borderBottom: "1px dashed #999",
        width: "30px",
        "&:focus": {
          border: "none",
          borderBottom: "1px dashed #999",
          outline: "none",
        },
        "&:focus-visible": {
          border: "none",
          borderBottom: "1px dashed #999",
          outline: "none",
        },
      },
    })
  );
  const classes = useStyles();
  const {t} = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [valueModalConfirm, setValueModalConfirm] = useState("");
  const [valueInputConfirm, setValueInputConfirm] = useState<any>("");
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleAddQuestion = (question: ExamQuestions) => {
    const cloneListAdded = [...listQuestionsAdded];
    const findItem = cloneListAdded?.findIndex((x) => x.id === question.id);
    if (findItem < 0) {
      cloneListAdded.push({...question});
      setListQuestionsAdded(cloneListAdded);
    } else {
      const newListAdded = cloneListAdded.filter((item) => item.id !== question.id);
      setListQuestionsAdded(newListAdded);
    }
  };
  const handleAddAllQuestion = (questions: ExamQuestions[], event: any) => {
    const checked = event.target.checked;
    if (!checked) {
      const filterArray = listQuestionsAdded.filter(
        (object1: any) =>
          !questions.some(
            (object2: any) =>
              object1.questionId === object2.questionId &&
              object1.examId === object2.examId &&
              object1.id === object2.id
          )
      );
      setListQuestionsAdded(filterArray);
    } else {
      const filterQuestionExists = [...listQuestionsAdded, ...questions].filter(
        (item, idx, self) => {
          const identity = item.id;
          return idx === self.findIndex((i) => i.id === identity);
        }
      );
      setListQuestionsAdded(filterQuestionExists);
    }
  };

  function isChecked(array1: any, array2: any) {
    for (let i = 0; i < array2.length; i++) {
      const element = array2[i];
      const found = array1.some((item: any) => JSON.stringify(item) === JSON.stringify(element));
      if (!found) {
        return false;
      }
    }
    return true;
  }

  const handleConfirmQuestion = () => {
    if (valueModalConfirm === "" && valueInputConfirm === 0) {
      toast.error("Vui lòng chọn một trong hai trường hợp");
      return;
    }
    if (valueModalConfirm === "2" && valueInputConfirm === 0) {
      toast.error("Vui lòng nhập số lượng câu hỏi muốn ngẫu nhiên");
      return;
    }

    if (valueModalConfirm === "2") {
      const shuffleArr = shuffleListQuestionAdded(listQuestionsAdded);
      setListQuestionsAdded(shuffleArr.slice(0, valueInputConfirm));
    }
    setOpenModal(false);
    setCreateQuestion(true);
    setIsPickOldExam(false);
  };

  const maxLengthCheck = (object: any) => {
    if (object.value.length > object.maxLength)
      object.value = object.value.slice(0, object.maxLength);
  };

  const shuffleListQuestionAdded = (array: any) => {
    for (var i = array.length - 1; i > 0; i--) {
      // Generate random number
      var j = Math.floor(Math.random() * (i + 1));

      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  };

  return (
    <React.Fragment>
      {data.map((item) => {
        return (
          <Box key={item.id} mb={2} position={"relative"}>
            <FormControl
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.checkAll}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    className={classes.checkbox_all}
                    checked={isChecked(listQuestionsAdded, item.examQuestions)}
                    onChange={(event) => handleAddAllQuestion(item.examQuestions, event)}
                  />
                }
                label={"Chọn tất cả"}
              />
            </FormControl>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box display="flex" width="100%">
                  <Box flexGrow={1} alignSelf={"center"}>
                    <Typography variant="h3" className={classes.title}>
                      {" "}
                      {item.examTitle}
                    </Typography>
                    <Typography variant="subtitle2">
                      <Typography
                        variant="subtitle1"
                        style={{fontWeight: 500, fontSize: 14, display: "inline"}}
                      >
                        Tổng câu
                      </Typography>
                      : {item.examQuestions.length}
                    </Typography>
                  </Box>
                  <Box width="50%" alignSelf={"center"}>
                    <Typography variant="h3" className={classes.title}>
                      {" "}
                      {dateTimeToFormatYYYYHHMM(item?.startDate)}
                    </Typography>
                    <Typography variant="subtitle2">
                      <Typography
                        variant="subtitle1"
                        style={{fontWeight: 500, fontSize: 14, display: "inline"}}
                      >
                        Tổng điểm
                      </Typography>
                      : <span style={{color: "#FD5468"}}>{item.totalPoint}</span> Điểm
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    style={{width: "5%", position: "absolute", right: 5, top: 6}}
                  >
                    {listQuestionsAdded.filter((x: any) => x.examId === item.id).length}/
                    {item.examQuestions.length}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{display: "block"}}>
                <Divider style={{width: "80%", textAlign: "center"}} />
                {item.examQuestions.map((x: ExamQuestions, index) => (
                  <Box key={x.id}>
                    <Box mt={2} className={classes.question} key={index}>
                      <Box mb={1}>
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex" alignItems={"center"}>
                            <Checkbox
                              color="primary"
                              className={classes.checkbox}
                              checked={listQuestionsAdded.some((item: any) => item.id === x.id)}
                              onChange={(event) => handleAddQuestion(x)}
                            />
                            <Typography variant="subtitle2" className={classes.question__content}>
                              {t("managerTest.cau")} {index + 1} {item.type === "TYPING" && ":"}
                            </Typography>{" "}
                            {item.type === "TYPING" && (
                              <Typography variant="subtitle2" style={{fontSize: 15, marginLeft: 5}}>
                                {x.question?.content}
                              </Typography>
                            )}
                          </Box>
                          <Typography variant="subtitle2">{x.question.point} Điểm</Typography>
                        </Box>
                        {item.type === "CHOICES" && (
                          <Typography variant="subtitle2">
                            {"  "}
                            {x.question?.content}
                          </Typography>
                        )}
                      </Box>
                      {x.question?.imageFileList.length > 0 && (
                        <Box
                          display="grid"
                          gridTemplateColumns={
                            item?.imageFileIDList?.length === 1 ? "1fr" : "1fr 1fr"
                          }
                          gridGap={10}
                          my="10px"
                        >
                          {x.question.imageFileList.map((file: any) => {
                            return (
                              <img
                                key={file.imageFileID}
                                style={{
                                  width: "100%",
                                  height: "fit-content",

                                  objectFit: "contain",
                                  maxHeight: "450px",
                                }}
                                src={file.imageFile.url}
                                alt={file.imageFileID}
                              />
                            );
                          })}
                        </Box>
                      )}
                      {x?.question?.answers?.map((answer: any, index: number) => (
                        <Box key={index}>
                          {item.type === "TYPING" ? (
                            <Typography variant="subtitle2">{answer.content}</Typography>
                          ) : (
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={answer?.isRightAnswer}
                                  disabled={!answer?.isRightAnswer}
                                />
                              }
                              label={
                                <Typography
                                  variant="subtitle2"
                                  style={{fontWeight: answer?.isRightAnswer ? 700 : 500}}
                                >
                                  {answer?.content}
                                </Typography>
                              }
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
      <Box mt={2} textAlign={"right"}>
        <Button
          onClick={() => {
            setIsShowMainExam(true);
            setIsPickOldExam(false);
          }}
          className={classes.btn_goBack}
        >
          {t("button.goback")}
        </Button>
        <Button
          className={classes.btn_create}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {t("button.next")}
        </Button>
      </Box>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        className={classes.root_modal}
      >
        <Slide direction="up" in={openModal}>
          <Box className={classes.modal__container}>
            <Box className={classes.modal__header}>
              <div></div>
              <Typography variant="subtitle2">Xác nhận số lượng câu hỏi</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.modal_content}>
              <RadioGroup
                name="oldExam"
                onChange={(event: any) => setValueModalConfirm(event.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Typography variant="subtitle2">
                      Tổng {listQuestionsAdded.length}/{listQuestionsAdded.length} câu
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Typography variant="subtitle2">
                      Ngẫu nhiên{" "}
                      <input
                        className={classes.input_confirm_question}
                        type="number"
                        value={valueInputConfirm}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value > listQuestionsAdded.length) {
                            setValueInputConfirm(listQuestionsAdded.length);
                          } else {
                            setValueInputConfirm(value);
                          }
                        }}
                      />{" "}
                      /{listQuestionsAdded.length}câu
                    </Typography>
                  }
                />
              </RadioGroup>
              <Box textAlign={"center"} mt={2}>
                <Button
                  className={classes.btn_create}
                  style={{
                    padding: "5px 10px",
                    fontSize: 12,
                  }}
                  onClick={handleConfirmQuestion}
                >
                  Đồng ý
                </Button>
              </Box>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </React.Fragment>
  );
}
