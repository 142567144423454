import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {makeStyles} from "@material-ui/styles";
import managerFilesApi from "api/managerFiles";
import BACKGROUND from "assets/img/bg_create_test.png";
import clsx from "clsx";
import InputField from "components/FormFields/InputField";
import {AnswerContent, ExamQuestions, ListExam, Question} from "models/managerTest";
import moment from "moment";
import React, {ChangeEvent, Fragment, useEffect, useMemo, useState} from "react";
import Dropzone from "react-dropzone";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import * as yup from "yup";
import DetailExam from "./DetailExam";

export interface CreateQuestionProps {
  valueForm: Question | undefined;
  setCreateQuestion: (e: any) => void;
  setValueForm: (e: any) => void;
  detailExam: ListExam | undefined;
}

export default function CreateQuestion({
  valueForm,
  setCreateQuestion,
  setValueForm,
  detailExam,
}: CreateQuestionProps) {
  const {t} = useTranslation();
  const initialValues = {
    title: detailExam?.examTitle,
  };

  const createSchema = (
    questions: string[],
    answers: any
    // , isRightAnswers: any
  ) =>
    yup.object().shape(
      questions.reduce(
        (acc: any, key: string) => ({
          ...acc,
          [key]: yup.string().min(1, `${t("managerTest.vuilongnhapcauhoibaithi")}`),
          title: yup.string().min(1, `${t("managerTest.vuilongnhaptieudebaithi")}`),
          ...answers,
          // ...isRightAnswers,
        }),
        {}
      )
    );

  const useStyles = makeStyles(() => ({
    background: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > img": {
        marginBottom: 20,
        objectFit: "cover",
        width: "60%",
      },
      "& >h6": {
        color: "#004673",
        fontSize: 25,
        fontWeight: 600,
        width: "17%",
      },
    },
    content: {
      backgroundColor: "#f3f3f3",
      borderRadius: 8,
      padding: 15,
      boxShadow: "0px 2px 4px rgba(168, 168, 168, 0.4)",
    },
    btn_copy: {
      margin: "0 15px",
    },
    icon: {
      borderRadius: "50%",
      width: 20,
      height: 20,
      boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "#f5f8fa",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#137cbd",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 20,
        height: 20,
        backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#106ba3",
      },
    },
    btn_submit: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      color: "#fff",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#E38825",
      },
    },
    btn_goBack: {
      borderRadius: 10,
      color: "#fff",
      marginRight: 15,
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
    },
    btn_add_picture: {
      border: "1px solid #c4c4c4",
      padding: 16,
      borderRadius: 4,
      cursor: "pointer",
      "&:hover": {
        color: "#499cff",
        border: "1px solid #000",
        transition: "all 0.5",
      },
    },
    image_iconClose: {
      position: "absolute",
      right: 10,
      top: 10,
      zIndex: 99,
      backgroundColor: "#FFF",
      borderRadius: "50%",
      width: 25,
      height: 25,
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  const [showDetailExam, setShowDetailExam] = useState(false);
  const [questions, setQuestions] = useState<any>([]);
  useEffect(() => {
    const currentQuestion = detailExam?.examQuestions?.map((item: ExamQuestions) => ({
      id: item.questionId,
      questionContent: item.question.content,
      point: Number(item.question.point) || "",
      imageFileIDList: item.question.imageFileList,
      answers: item.question.answers.map((x) => ({
        answerContent: x.content,
        isRightAnswer: x.isRightAnswer,
      })),
    }));
    setQuestions(currentQuestion);
  }, []);
  const {control, handleSubmit} = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
    resolver: yupResolver(
      createSchema(
        questions.map((x: any, index: number) => `questions-${index}`),
        questions
          .reduce((prevValue: any, currentValue: any, currentIndex: number) => {
            return [
              ...prevValue,
              ...currentValue?.answers?.map(
                (answer: any, index: number) => `answerContent-${currentIndex}-${index}`
              ),
            ];
          }, [])
          .reduce((acc: string[], key: string) => {
            return {
              ...acc,
              [key]: yup.string().min(1, `${t("managerTest.vuilongnhapdapancuacauhoi")}`),
            };
          }, {})
      )
    ),
  });

  const handleSubmitForm = async () => {
    if (valueForm && valueForm?.totalPoint > 0) {
      const pointQuestion = questions.reduce((x: any, item: any) => (x += Number(item.point)), 0);
      if (pointQuestion > valueForm?.totalPoint) {
        toast.error(`${t("managerTest.tongsodiemlonhondiemtongdanhap")}`);
        return;
      } else if (
        pointQuestion === valueForm?.totalPoint &&
        questions.some((item: any) => item.point === "")
      ) {
        toast.error(`${t("managerTest.tongsodiemlonhondiemtongdanhap")}`);
        return;
      } else if (
        pointQuestion < valueForm?.totalPoint &&
        questions.every((item: any) => item.point !== "")
      ) {
        toast.error(`${t("managerTest.tongsodiemlonhondiemtongdanhap")}`);
        return;
      }
    }
    if (valueForm?.startDate?.slice(-5)) {
      const currentHours = new Date().getHours();
      const currentMinutes = new Date().getMinutes();
      const currentDay = moment(new Date()).format("YYYY-MM-DD");
      const currentTimeStamp = currentHours * 3600 + currentMinutes * 60;
      const timeClicked =
        Number(valueForm.startDate?.slice(-5).slice(0, 2)) * 3600 +
        Number(valueForm.startDate?.slice(-5).slice(-2)) * 60;
      if (currentTimeStamp > timeClicked && currentDay === valueForm?.startDate?.slice(0, 10)) {
        toast.error(
          `${t("managerTest.vuilongquaylaichonngaygiokiemtralonhonhoacbangngaygiokiemtrahientai")}`
        );
        return;
      }
    }

    if (valueForm?.category === "essay") {
      setShowDetailExam(true);
      return;
    }
    let index = 0;

    questions.map((x: any, idx: number) => {
      index = idx;
      if (
        x.answers.every((y: AnswerContent) => !y.isRightAnswer) &&
        valueForm?.category === "multipleChoice"
      ) {
        toast.error(`${t("managerTest.vuilongnhapdapancuacauhoi")} ${index + 1}`);
        setShowDetailExam(false);
      } else {
        setShowDetailExam(true);
      }
    });
  };

  const handleAddAnswer = (index: number) => {
    const optionsOfQuestion = [...questions];
    if (optionsOfQuestion[index].answers.length < 10) {
      optionsOfQuestion[index].answers.push({
        answerContent: "",
        isRightAnswer: false,
      });
    } else {
      toast.error(`${t("managerTest.toida10cautraloi")}`);
    }
    setQuestions(optionsOfQuestion);
  };

  const handleAddQuestion = () => {
    setQuestions((questions: any) => [
      ...questions,
      {
        id: Math.random(),
        questionContent: "",
        point: "",
        imageFileIDList: [],
        answers: [
          {
            answerContent: "",
            isRightAnswer: false,
          },
        ],
      },
    ]);
  };

  const handleCopyQuestion = (index: number) => {
    const qs = [...questions];
    let newQs = qs[index];
    setQuestions([
      ...questions,
      {
        questionContent: newQs.questionContent,
        point: newQs.point,
        imageFileIDList: newQs.imageFileIDList,
        answers: newQs.answers.map((answer: AnswerContent) => ({
          answerContent: answer.answerContent,
          isRightAnswer: answer.isRightAnswer,
        })),
      },
    ]);
  };

  const handleDeleteQuestion = (index: number) => {
    const qs = [...questions];
    if (questions.length > 1) {
      qs.splice(index, 1);
    }
    setQuestions(qs);
  };
  const handleDeleteAnswer = (i: number, j: number) => {
    var optionsOfQuestion = [...questions];
    if (optionsOfQuestion[i].answers.length > 1) {
      optionsOfQuestion[i].answers.splice(j, 1);
      setQuestions(optionsOfQuestion);
    }
  };

  const handleChangeOptionValue = (value: string, i: number, j: number) => {
    const optionsOfQuestion = [...questions];
    optionsOfQuestion[i].answers[j].answerContent = value;
    setQuestions(optionsOfQuestion);
  };

  const handleChangeQuestionValue = (value: string, i: number) => {
    const optionsOfQuestion = [...questions];
    optionsOfQuestion[i].questionContent = value;
    setQuestions(optionsOfQuestion);
  };

  const handleChangePointValue = (value: string, i: number) => {
    const optionsOfQuestion = [...questions];
    optionsOfQuestion[i].point = value;
    setQuestions(optionsOfQuestion);
  };

  const handleChangeTitleQuestion = (value: string) => {
    setValueForm({...valueForm, title: value});
  };

  const handleChangeRadioAnswer = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: number,
    j: number
  ) => {
    setIsRightAnswer(i);
    const optionsOfQuestion = [...questions];
    optionsOfQuestion[i].answers[j].isRightAnswer = event.target.checked;
    setQuestions(optionsOfQuestion);
  };

  const setIsRightAnswer = (i: number) => {
    let qs = [...questions];
    const answerRight = qs[i].answers;

    for (let j = 0; j < answerRight.length; j++) {
      answerRight[j].isRightAnswer = false;
    }
    setQuestions(qs);
  };

  const handleAddPicture = async (acceptedFiles: any, i: number) => {
    const formData = new FormData();
    try {
      acceptedFiles.forEach((acceptedFile: any) => {
        formData.append("attachments", acceptedFile);
      });
      const data = await managerFilesApi.uploadFiles(formData);
      const optionsOfQuestion = [...questions];
      optionsOfQuestion[i].imageFileIDList = [
        ...optionsOfQuestion[i].imageFileIDList,
        ...data.body?.attachmentList,
      ];
      setQuestions(optionsOfQuestion);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteImage = (indexArr: number, idxImage: number) => {
    const optionsOfQuestion = [...questions];
    optionsOfQuestion[indexArr].imageFileIDList.splice(idxImage, 1);
    setQuestions(optionsOfQuestion);
  };
  return (
    <>
      <Box>
        {showDetailExam ? (
          <DetailExam
            listQuestion={questions}
            valueForm={valueForm}
            setShowDetailExam={setShowDetailExam}
          />
        ) : (
          <>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Box className={classes.background}>
                <Typography variant="subtitle2">
                  {t("managerTest.taodethi").toUpperCase()}
                </Typography>
                <img src={BACKGROUND} alt="BACKGROUND" />
              </Box>
              <InputField
                name="title"
                label={t("managerTest.nhaptieudebaithi").toUpperCase()}
                control={control}
                rows={2}
                multiline
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChangeTitleQuestion(event.target.value)
                }
              />
              {questions.map((question: any, index: number) => (
                <Box className={classes.content} mt={2} key={index}>
                  <Box display="flex" alignItems="center" key={index}>
                    <InputField
                      control={control}
                      name={`questions-${index}`}
                      label={`${t("managerTest.cau")} ${index + 1}`}
                      value={question.questionContent}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeQuestionValue(event.target.value, index)
                      }
                    />
                    <Box width="10%" ml={3}>
                      <InputField
                        name={`point-${index}`}
                        control={control}
                        value={question.point}
                        label={t("managerTest.diem")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleChangePointValue(event.target.value, index)
                        }
                      />
                    </Box>
                    <Dropzone onDrop={(event: any) => handleAddPicture(event, index)}>
                      {({getRootProps, getInputProps}) => (
                        <Box width="15%" ml={3} {...getRootProps()}>
                          <label htmlFor="button-add-picture" className={classes.btn_add_picture}>
                            {t("managerTest.themanh")}
                          </label>
                          <input {...getInputProps()} accept="image/*" />
                        </Box>
                      )}
                    </Dropzone>
                  </Box>
                  {question?.imageFileIDList?.length > 0 && (
                    <Box
                      display="grid"
                      gridTemplateColumns={
                        question?.imageFileIDList?.length === 1 ? "1fr" : "1fr 1fr"
                      }
                      gridGap={10}
                      my="10px"
                    >
                      {question.imageFileIDList.map((x: any, idxImage: number) => {
                        return (
                          <Box key={x.id} position="relative">
                            <Box
                              className={classes.image_iconClose}
                              onClick={() => handleDeleteImage(index, idxImage)}
                            >
                              <CloseIcon />
                            </Box>
                            <img
                              style={{
                                width: "100%",
                                height: "fit-content",
                                objectFit: "contain",
                                maxHeight: "450px",
                              }}
                              src={x?.url ?? x?.imageFile?.url}
                              alt={x}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                  <RadioGroup name="radios-button" aria-label="radios-button">
                    {question.answers.map((answer: AnswerContent, index2: number) => (
                      <Fragment key={index2}>
                        <Box alignItems="center" display="flex" justifyContent="space-between">
                          {/* Câu hỏi tự luận */}
                          {valueForm?.category === "essay" ? (
                            <Box mt={2} width="100%">
                              <InputField
                                label={t("managerTest.dapan")}
                                name={`answerContent-${index}-${index2}`}
                                value={answer.answerContent}
                                control={control}
                                rows={3}
                                multiline
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChangeOptionValue(event.target.value, index, index2)
                                }
                              />
                            </Box>
                          ) : (
                            <Box
                              justifyContent="flex-end"
                              display="flex"
                              alignItems="center"
                              mt={1}
                              width="60%"
                            >
                              {/* Câu hỏi trắc nghiệm*/}
                              <FormControlLabel
                                control={
                                  <Radio
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                      handleChangeRadioAnswer(event, index, index2)
                                    }
                                    disableRipple
                                    checked={answer.isRightAnswer}
                                    value={`radio-${index}-${index2}`}
                                    name={`radio-${index}-${index2}`}
                                    icon={<span className={classes.icon} />}
                                    checkedIcon={
                                      <span className={clsx(classes.icon, classes.checkedIcon)} />
                                    }
                                    style={{marginTop: 15}}
                                  />
                                }
                                label=""
                              />

                              <InputField
                                label={`${t("managerTest.dapan")} ${index2 + 1}`}
                                name={`answerContent-${index}-${index2}`}
                                value={answer.answerContent}
                                control={control}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                  handleChangeOptionValue(event.target.value, index, index2)
                                }
                              />
                            </Box>
                          )}
                          {valueForm?.category === "multipleChoice" && (
                            <Box>
                              <Tooltip title={`${t("managerTest.xoadapan")} ${index2 + 1}`}>
                                <IconButton onClick={() => handleDeleteAnswer(index, index2)}>
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      </Fragment>
                    ))}
                  </RadioGroup>
                  {valueForm?.category === "multipleChoice" && (
                    <Box mt={2}>
                      <Typography
                        variant="body2"
                        component="span"
                        onClick={() => handleAddAnswer(index)}
                        color="primary"
                        style={{fontSize: 16, cursor: "pointer", marginLeft: 5}}
                      >
                        {t("managerTest.them")}
                      </Typography>
                    </Box>
                  )}
                  <Box textAlign="right" borderTop="1px solid #AAAAAA" mt={1}>
                    <Box mt={1}>
                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        color="primary"
                        onClick={handleAddQuestion}
                      >
                        {t("managerTest.them")}
                      </Button>

                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        color="primary"
                        onClick={() => handleCopyQuestion(index)}
                        className={classes.btn_copy}
                      >
                        {t("managerTest.saochep")}
                      </Button>

                      <Button
                        startIcon={<DeleteOutlineIcon />}
                        color="secondary"
                        onClick={() => handleDeleteQuestion(index)}
                      >
                        {t("button.delete")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))}

              <Box textAlign="right" mt={3}>
                <Button onClick={() => setCreateQuestion(false)} className={classes.btn_goBack}>
                  {t("button.goback")}
                </Button>

                <Button
                  type="submit"
                  className={classes.btn_submit}
                  // onClick={() => {
                  //   if (
                  //     questions.map((x) => x.answers.some((y: AnswerContent) => !y.isRightAnswer))
                  //   )
                  //     return setShowDetailExam(true);
                  // }}
                >
                  {t("managerTest.xong")}
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
      {/* )} */}
    </>
  );
}
