import {
  Box,
  Button,
  createStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {makeStyles, withStyles} from "@material-ui/styles";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {toast} from "react-toastify";
import BACKGROUND from "assets/img/bg_list_test.png";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {dateTimeToFormatYYYY} from "utils/dateTimeFormat";
import {timeConvert} from "utils/timeConvert";
import {ListResponseExam, ListExam} from "models/managerTest";
import managerTestApi from "api/managerTest";
import {useConfirm} from "material-ui-confirm";
import {
  fetchListExam,
  SelectFilterListExam,
  SelectListExam,
  setFilterListExam,
} from "./managerExamListSlice";
import moment from "moment";
import EmptyComponent from "components/Empty";
import {fetchDataOptions, selectListClass} from "features/Auth/options/optionsSlice";

export interface ManagerExamProps {}

export default function ManagerExam(props: ManagerExamProps) {
  const useStyles = makeStyles(() => ({
    background: {
      backgroundImage: `url(${BACKGROUND})`,
      backgroundSize: "cover",
      backgroundPositionX: "right",
      backgroundRepeat: "no-repeat",
      height: 150,
      width: "100%",
      position: "relative",
      "& >h6": {
        position: "absolute",
        bottom: 30,
        left: 0,
        color: "#004673",
        fontSize: 20,
        fontWeight: 700,
      },
    },

    btn_listExam: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
      margin: "0 10px",
    },
    btn_delete: {
      background: "linear-gradient(0deg, #FD5468 0%, rgba(255, 193, 200, 0.9) 169.32%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
      margin: "0 10px",
    },
    btn_edit: {
      background: "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
      borderRadius: 10,
      fontSize: 11,
      color: "#fff",
      padding: "5px 12px",
      margin: "0 7px",
    },
    btn_detail: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
    },
    btn_list_missing: {
      background: "linear-gradient(0deg, #10BCA7 0%, rgba(255, 239, 156, 0.9) 165.52%)",
      borderRadius: 10,
      fontSize: 12,
      color: "#fff",
      padding: "4px 10px",
    },
    container: {
      maxHeight: 500,
      "& .MuiButton-root.Mui-disabled": {
        color: "#fff",
      },
    },
  }));

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        padding: "16px 5px",
        // backgroundColor: "#F3F3F3CC",
        color: "#1B1B1B",
        fontSize: 11,
        fontWeight: 700,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid #777777",
        zIndex: 99,
        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
      body: {
        padding: "16px 5px",
        fontSize: 11,
        borderRight: "1px solid rgba(224, 224, 224, 0.8)",

        [theme.breakpoints?.up("xl")]: {
          fontSize: 13,
        },
      },
    })
  )(TableCell);
  const confirm = useConfirm();
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filter = useAppSelector(SelectFilterListExam);
  const listExam = useAppSelector(SelectListExam);
  const classList = useAppSelector(selectListClass);

  useEffect(() => {
    dispatch(fetchListExam(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    dispatch(fetchDataOptions());
  }, [dispatch]);

  const handleChangePage = (page: number) => {
    dispatch(
      setFilterListExam({
        ...filter,
        page: page - 1,
      })
    );
  };
  const deleteExam = async (exam: any) => {
    confirm({
      title: (
        <Typography variant="h5" color="secondary">
          {t("delete.chuy")}
        </Typography>
      ),
      description: (
        <Typography variant="subtitle2">
          {t("delete.khiban")}{" "}
          <Typography variant="subtitle2" component="span" color="secondary">
            {t("delete.dongy")}
          </Typography>{" "}
          {t("delete.xoa")}
          <Typography variant="subtitle2" component="span" style={{color: "#004673"}}>
            {" "}
            {exam.examTitle}
          </Typography>{" "}
          {t("delete.sexoatatcadulieu")}
        </Typography>
      ),
      confirmationText: <Button className={classes.btn_delete}>{t("delete.dongy")}</Button>,
      cancellationText: (
        <Button className={classes.btn_edit} style={{margin: 0}}>
          {t("delete.huybo")}
        </Button>
      ),
    }).then(async () => {
      try {
        const res: ListResponseExam<ListExam> = await managerTestApi.deleteExam(exam.id);
        if (res.responseCode === "00") {
          toast.success(`${t("managerTest.xoabaithithanhcong")}`);
          dispatch(fetchListExam(filter));
        }
      } catch (error) {}
    });
  };

  const handleCheckDisable = (exam: ListExam) => {
    const timeStartExam = new Date(exam.startDate).getTime();
    const difference = moment(timeStartExam).diff(moment(), "minutes");
    if (difference < 15) {
      return true;
    }
    return false;
  };
  return (
    <Box>
      <Box className={classes.background}>
        <Typography variant="subtitle2">{t("managerTest.danhsachdethi")}</Typography>
      </Box>

      <Box>
        {listExam?.totalPages > 0 ? (
          <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader style={{minWidth: "1600px"}}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" width="70px">
                    {t("stt")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="100px">
                    {t("managerTest.ngaytaode")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="200px">
                    {t("managerTest.tieudebaithi")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="100px">
                    {t("managerTest.thoigian")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="120px">
                    {t("managerTest.hinhthuc")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="90px">
                    {t("managerTest.socauhoi")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="150px">
                    {t("managerTest.class")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="70px">
                    {t("managerTest.diem")}
                  </StyledTableCell>
                  <StyledTableCell align="center" width="350px">
                    {t("thaotac")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listExam?.content.map((exam, index) => (
                  <TableRow key={exam.id}>
                    <StyledTableCell align="center" component="th" scope="exam">
                      {listExam.size * listExam.number + index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dateTimeToFormatYYYY(exam.createdAt)}
                    </StyledTableCell>
                    <StyledTableCell align="center">{exam?.examTitle}</StyledTableCell>
                    <StyledTableCell align="center">
                      {timeConvert(Number(exam?.examTime))}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {exam?.type === "TYPING"
                        ? `${t("managerTest.tuluan")}`
                        : `${t("managerTest.tracnghiem")}`}
                    </StyledTableCell>
                    <StyledTableCell align="center">{exam?.examQuestions?.length}</StyledTableCell>
                    <StyledTableCell align="center">
                      {exam?.classList?.length === classList?.length
                        ? "Tất cả"
                        : exam?.classList?.map((x: any) => x.name).join(" , ")}
                    </StyledTableCell>
                    <StyledTableCell align="center">{exam?.totalPoint}</StyledTableCell>

                    <StyledTableCell align="center">
                      <Button
                        className={classes.btn_detail}
                        onClick={() => navigate(`/admin/manager-test/exam/${exam.id}`)}
                      >
                        {t("button.detail")}
                      </Button>
                      <Button
                        className={classes.btn_listExam}
                        onClick={() => navigate(`/admin/manager-test/exam/list-user/${exam.id}`,{ state:{
                          disabled:handleCheckDisable(exam)
                        }})}
                        style={{marginRight: 0}}
                      >
                        {t("managerTest.dshocvien")}
                      </Button>
                      <Button
                        className={classes.btn_listExam}
                        onClick={() => navigate(`/admin/manager-test/exam/list-test/${exam.id}`)}
                      >
                        {t("managerTest.dsbaithi")}
                      </Button>
                      <Button
                        className={classes.btn_list_missing}
                        onClick={() => navigate(`/admin/manager-test/exam/list-skip/${exam.id}`)}
                      >
                        {t("managerTest.dsbothi")}
                      </Button>
                      <Button
                        className={classes.btn_listExam}
                        onClick={() => navigate(`/admin/manager-test/exam/edit/${exam.id}`)}
                        disabled={handleCheckDisable(exam)}
                        style={{
                          marginTop: 10,
                          background: handleCheckDisable(exam)
                            ? "rgb(198 190 190)"
                            : "linear-gradient(360deg, #399BD9 0%, #83CFFF 172.67%)",
                        }}
                      >
                        {t("admin.chinhsua")}
                      </Button>
                      <Button
                        className={classes.btn_delete}
                        style={{margin: 0, marginTop: 10}}
                        onClick={() => deleteExam(exam)}
                      >
                        {t("managerTest.xoabaithi")}
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyComponent isBorder={false} />
        )}
        {listExam?.totalPages > 1 && (
          <Box mt={3} display="flex" justifyContent="space-between">
            <Typography variant="subtitle2" style={{fontWeight: 600}}>
              {t("managerTest.tongdethi")}: {listExam.totalElements}
            </Typography>
            <Pagination
              count={Math.ceil(listExam?.totalElements / listExam?.size)}
              page={listExam?.number + 1}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={(e, page) => handleChangePage(page)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
